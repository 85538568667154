.container {
  position: relative;
}

.wrapper {
  flex-direction: column;
  display: flex;
  align-items: center;
  padding: 0 40px;
  gap: 20px;

  @media (--large) {
    flex-direction: row;
    padding: 50px 0;
    gap: 4%;
  }
}

.text-column {
  width: 100%;
  @media (--large) {
    width: 25%;
  }
}

.tag {
  display: flex;
  justify-content: flex-start;
  gap: calc(34px - 3ch);
  margin-bottom: 10px;

  & :first-child {
    margin-left: 0;
    width: 3ch;
  }

  & > p {
    justify-self: center;
    font-size: var(--font-size-xs);
    letter-spacing: var(--body-ls);
    font-weight: 500;
    line-height: 1.2;
    &:hover {
      cursor: pointer;
    }
  }
}

.image {
  width: 100%;
  height: 100%;
  max-width: 80vw;
}
