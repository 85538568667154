.tag {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  z-index: 10;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  background-color: var(--color-white);

  position: absolute;
  display: flex;

  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  transform: translate(-50%, -50%);
  flex-wrap: nowrap;
  transition: opacity 100ms ease;

  & > p {
    height: auto;
    width: auto;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
  }

  &.inactive {
    opacity: 70%;
    z-index: 100;
  }

  &:hover {
    cursor: pointer;
  }
}

.info {
  z-index: 210;
  position: absolute;
  text-decoration: none;
  background-color: white;
  gap: 15px;
  display: flex;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 5px;
  width: 300px;

  @media (--mobile-only) {
    display: none;
  }
}

.tagWrapper {
  position: relative;
  height: 97px;
  width: 74px;
}
.infoWrapper {
  padding: 5px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.price {
  display: flex;
  flex-direction: column;

  & .discountPrice {
    color: var(--color-black);
    text-decoration: line-through;
  }
}
.arrow {
  position: absolute;
  bottom: 10px;
  right: 15px;
  transform: rotate(90deg);
}
