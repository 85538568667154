.root {
  position: relative;

  @media (--large) {
    padding-inline: 8vw;
    column-gap: 2rem;
    overflow: hidden;
  }

  &.column__root {
    display: none;
    flex-direction: column;
    overflow: hidden;
    gap: 6rem;
  }
}

.slider-wrapper {
  margin-left: var(--page-inset);

  @media (--large) {
    .side-by-side & {
      margin-left: 0;
    }
  }
}
